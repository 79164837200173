import * as React from 'react';
import { Link } from 'gatsby';
import DefaultLayout from '../layouts/Default';

const NotFoundPage = () => (
	<>
		<div className="relative flex items-center justify-center h-screen overflow-hidden bg-white">
			<span className="absolute z-10 block w-full tracking-tight font-bold text-center top-16  text-[48vw] opacity-10 leading-none lg:text-[33vw] xl:text-[30rem] text-brand-200">
				404
			</span>
			<div className="relative z-20 flex flex-col w-full px-5 pb-64 text-center pt-36 flex-nowrap lg:max-w-3xl lg:mx-auto">
				<h1 className="text-2xl text-black md:text-4xl">Page not found</h1>
				<p className="mb-6 text-lg text-black md:text-xl">
					Sorry, we couldn’t find what you were looking for.
				</p>
				<div>
					<Link
						to="/"
						className="inline-block px-2 py-1 text-base font-bold text-center bg-white border cursor-pointer md:text-lg lg:mr-1 whitespace-nowrap border-secondary-400 lg:my-1 hover:shadow-md focus:bg-brand-100 focus:text-white xl:text-base lg:border xl:px-3"
					>
						Go to home
					</Link>
				</div>
			</div>
		</div>
	</>
);

const WrappedNotFoundPage = (props) => (
	<DefaultLayout fullWidth className="h-screen">
		<NotFoundPage {...props} />
	</DefaultLayout>
);

export default WrappedNotFoundPage;
